import React from 'react';
import { Tabs, Tab } from '@mui/material';

const TabList = ({ tabs, activeTab, setActiveTab }) => {
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Tabs value={activeTab} onChange={handleTabChange} aria-label="tab list">
            {tabs.map((tab) => (
                <Tab key={tab.id} label={tab.name} value={tab.id} />
            ))}
        </Tabs>
    );
};

export default TabList;
