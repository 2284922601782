
import { Button } from '@mui/material';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomBarChart = ({

    //data, 
    key1, key2, onBack }) => {

    const data = [
        {
            name: 'Page A',
            "Total Number Of loans": 4000,
            "Total loan Amount": 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            "Total Number Of loans": 3000,
            "Total loan Amount": 1398,
            amt: 2210,
        },

    ];
    key1 = "Total Number Of loans"
    key2 = "Total loan Amount"
    if (!data || !key1 || !key2) {
        return <div>Invalid data or keys provided</div>;
    }

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: '20px' }}
                onClick={onBack}
            >
                Back
            </Button>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey={key1} stackId="a" fill="#8884d8" />
                    <Bar dataKey={key2} stackId="a" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CustomBarChart;
