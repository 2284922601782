
// import React from 'react';
// import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const CustomGroupedBarChart = ({ data }) => {
//     console.log(" Data Received GroupedBarChart ", JSON.stringify(data, null, 2));


//     const chartData = data.map(item => ({
//         name: item.head,
//         "Application Submitted": item.value1 || 0,
//         "Application Approved": item.value2 || 0,
//         "Application Approval Rate (%)": item.value2 && item.value1 ? ((item.value2 / item.value1) * 100).toFixed(2) : 0,
//     }));

//     return (
//         <ResponsiveContainer width="100%" height={300}>
//             <BarChart
//                 data={chartData}
//                 margin={{
//                     top: 5,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                 }}
//             >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend />
//                 <Bar dataKey="Application Approved" fill="#8884d8" activeBar={<Rectangle fill="#8884d8" stroke="white" />} />
//                 <Bar dataKey="Application Submitted" fill="#82ca9d" activeBar={<Rectangle fill="#82ca9d" stroke="white" />} />
//                 <Bar dataKey="Application Approval Rate (%)" fill="#ff7300" />
//             </BarChart>
//         </ResponsiveContainer>
//     );
// };

// export default CustomGroupedBarChart;
// import React from 'react';
// import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// const CustomGroupedBarChart = ({ data }) => {
//     console.log(" Data Received GroupedBarChart ", JSON.stringify(data, null, 2));

//     const chartData = data.map(item => ({
//         name: item.head,
//         "Application Submitted": item.value1 || 0,
//         "Application Approved": item.value2 || 0,
//         "Application Approval Rate (%)": item.value2 && item.value1 ? ((item.value2 / item.value1) * 100).toFixed(2) : 0,
//     }));

//     return (
//         <ResponsiveContainer width="100%" height={300}>
//             <BarChart
//                 data={chartData}
//                 margin={{
//                     top: 5,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                 }}
//             >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="name" />
//                 {/* Left Y-axis for Application Submitted and Approved */}
//                 <YAxis />

//                 {/* Right Y-axis for Approval Rate */}
//                 <YAxis
//                     orientation="right"
//                     axisLine={false}
//                     tickFormatter={(value) => `${value}%`}
//                     yAxisId={1} // Ensure the second Y-axis is identified
//                 />

//                 <Tooltip />
//                 <Legend />

//                 {/* Bar for Application Approved */}
//                 <Bar dataKey="Application Approved" fill="#8884d8" activeBar={<Rectangle fill="#8884d8" stroke="white" />} />

//                 {/* Bar for Application Submitted */}
//                 <Bar dataKey="Application Submitted" fill="#82ca9d" activeBar={<Rectangle fill="#82ca9d" stroke="white" />} />

//                 {/* Bar for Application Approval Rate connected to the right Y-axis */}
//                 <Bar dataKey="Application Approval Rate (%)" fill="#ff7300" yAxisId={1} /> {/* Link this Bar to the secondary Y-axis */}
//             </BarChart>
//         </ResponsiveContainer>
//     );
// };

// export default CustomGroupedBarChart;
import React from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';

// const data = [
//     {
//         head: "2023-08",
//         value1: 30,
//         value2: 20,
//         value3: 5,
//     },
//     {
//         head: "2023-09",
//         value1: 40,
//         value2: 20,
//         value3: 10,
//     },
//     {
//         head: "2023-10",
//         value1: 17,
//         value2: 12,
//         value3: 23,
//     },
//     {
//         head: "2023-11",
//         value1: 9,
//         value2: 6,
//         value3: 12,
//     },
//     {
//         head: "2023-12",
//         value1: 4,
//         value2: 1,
//         value3: null,
//     },
// ];

// Preparing the chart data


const CustomGroupedBarChart = ({ data }) => {

    const chartData = data.map(item => ({
        name: item.head,
        "Applications Submitted": item.value1,
        "Applications Approved": item.value2,
        "Approval Rate (%)": item.value1 > 0 ? ((item.value2 / item.value1) * 100).toFixed(2) : 0,
    }));
    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 20,
                }}
            >
                {/* Cartesian Grid */}
                <CartesianGrid strokeDasharray="3 3" />

                {/* X-Axis */}
                <XAxis dataKey="name" />

                {/* Left Y-Axis for Bar Values */}
                <YAxis yAxisId="left" />

                {/* Right Y-Axis for Approval Rate */}
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={(value) => `${value}%`}
                    domain={[0, 100]}
                />

                {/* Tooltip and Legend */}
                <Tooltip />
                <Legend />

                {/* Bars */}
                <Bar yAxisId="left" dataKey="Applications Submitted" fill="#82ca9d" />
                <Bar yAxisId="left" dataKey="Applications Approved" fill="#8884d8" />

                {/* Line for Approval Rate */}
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Approval Rate (%)"
                    stroke="#ff7300"
                    strokeWidth={3}
                    dot={{ r: 6, fill: "#ff7300" }}
                    activeDot={{ r: 8 }}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default CustomGroupedBarChart;
