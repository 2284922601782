
import React, { useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { KSReportBarIcon, KSReportSearchIcon, KSReportAllIcon } from '../../icons/custome-icon';

const GridDisplay = ({ content, handleButtonClick }) => {
    const icons = [KSReportBarIcon, KSReportSearchIcon, KSReportAllIcon];


    const [expanded, setExpanded] = useState({});


    const toggleExpand = (index) => {
        setExpanded((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
            {content.map((item, index) => {
                const IconComponent = icons[index % icons.length];
                const isExpanded = expanded[index];
                const displayDescription = isExpanded
                    ? item.description
                    : `${item.description.slice(0, 150)}${item.description.length > 150 ? '...' : ''}`;

                return (
                    <Grid item xs={12} md={6} key={index}>
                        <Paper elevation={3} style={{ padding: '20px' }}>
                            <IconComponent />
                            <Typography></Typography>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                style={{ cursor: 'pointer', color: '#1976d2' }}
                                onClick={() => handleButtonClick(index)}
                            >
                                {item.heading}
                            </Typography>
                            <Typography></Typography>
                            <Typography variant="body1">
                                {displayDescription}{' '}
                                {item.description.length > 100 && (
                                    <a
                                        href="#"
                                        style={{ color: '#1976d2', textDecoration: 'none', fontWeight: 'bold' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleExpand(index);
                                        }}
                                    >
                                        {isExpanded ? 'read less' : 'read more'}
                                    </a>
                                )}
                            </Typography>
                        </Paper>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default GridDisplay;
