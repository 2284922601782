
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CustomStackedBarChart = ({ onBack, data }) => {
    const sampleData = [
        {
            "head": "active",
            "value1": 346,
            "value2": 97296518.45
        },
        {
            "head": "closed",
            "value1": 74,
            "value2": 27566266
        },
        {
            "head": "incomplete",
            "value1": 421,
            "value2": 149836207
        },
        {
            "head": "in progress",
            "value1": 46,
            "value2": 18758674
        },
        {
            "head": "locked",
            "value1": 1,
            "value2": 0
        },
        {
            "head": "archived",
            "value1": 26,
            "value2": 9467035
        },
        {
            "head": "rejected",
            "value1": 6,
            "value2": 1293329
        },
        {
            "head": "all",
            "value1": 1,
            "value2": 0
        }
    ];

    const formatValue = (value) => {
        if (value >= 100000) {
            return `${(value / 100000).toFixed(2)}L`;
        } else if (value >= 1000) {
            return `${(value / 1000).toFixed(2)}K`;
        } else {
            return value.toString();
        }
    };

    const formattedData = data.map(item => ({
        ...item,
        value2: formatValue(item.value2)
    }));

    // Custom tooltip formatter
    const customTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
                    <p><strong>{payload[0].payload.head}</strong></p>
                    <p>No of Loans: {payload[0].value}</p>
                    <p>Loan Amount: {payload[1].value}</p>
                </div>
            );
        }
        return null;
    };

    // Custom legend formatter
    const customLegend = (value) => {
        return value === 'value1' ? 'No of Loans' : 'Loan Amount';
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={formattedData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                barSize={40}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="head" />
                <YAxis />
                <Tooltip content={customTooltip} />
                <Legend formatter={customLegend} />
                <Bar dataKey="value1" stackId="a" fill="#8884d8" />
                <Bar dataKey="value2" stackId="a" fill="#82ca9d" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CustomStackedBarChart;
