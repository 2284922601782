


import React, { useEffect, useState } from 'react';
import TabList from './TabList';
import GridDisplay from './GridDisplay';
import { handleGridButtonClick } from './handlers';
import Chart1 from './charts/Chart1';
import Chart2 from './charts/Chart2';
import Chart3 from './charts/Chart3';
import Chart4 from './charts/CustomPieChart';
import CustomBarChart from './charts/CustomBarChart';
import CustomPieChart from './charts/CustomPieChart';
import { getApiCallDynamic } from '../../services/api-service';
import { API } from '../../services/api-config';
import { StackedBarChart } from '@mui/icons-material';
import CustomStackedBarChart from './charts/CustomStackedBarChart';
import { ChartContainer } from './charts/ChartContainer';
import { Typography } from '@mui/material';

const DashboardMain = () => {
    // Predefined tabs and content
    const initialTabs = [
        {
            id: 1,
            name: 'Mortgage Originator KPIs',
            content: [
                { heading: 'Monthly Loan Product Analysis', description: 'This reports gives analysis of monthly productivity of loans bifurcated on basis of Loan Terms & Loan Types.' },
                { heading: 'Broker Loan Volume', description: ' Report gives detail analysis of performance of broker for that particular year and particular date range. It provide overview of pipeline under particular broker. It gives insights such total intake in term of Prospect, loan amount, Volume, Current active pipeline, closed loans.' },
                { heading: 'Sample', description: 'This reports gives analysis of monthly productivity of loans bifurcated on basis of Loan Terms & Loan Types.' },
                { heading: 'Application Approval Rate', description: 'This reports gives analysis of monthly productivity of loans bifurcated on basis of Loan Terms & Loan Types.' }

            ],
        },
        {
            id: 2,
            name: 'Mortgage Call Report',
            content: [
                { heading: 'Loan Category', description: 'Description for Funding Rate' },
                { heading: 'Loan Term', description: 'Description for Approval Rate' },
            ],
        },
    ];

    const [activeTab, setActiveTab] = useState(1);
    const [showChart, setShowChart] = useState(false);
    const [activeComponent, setActiveComponent] = useState(null); // State to manage active chart

    const activeContent = initialTabs.find(tab => tab.id === activeTab)?.content || [];

    const handleBack = () => {
        setShowChart(false);
    };

    const [loanTermPercentage, setLoanTermPercentage] = useState(null)
    const [loanTypePercentage, setLoanTypePercentage] = useState(null)
    const [loanMilesData, setLoanMilesData] = useState(null)
    const [aarData, setAarData] = useState(null)

    const initialData = [
        { name: 'Group A', value: 2.5, date: '2024-09-01' },
        { name: 'Group B', value: 4.0, date: '2024-10-01' },
        { name: 'Group C', value: 3.5, date: '2024-11-01' },
        { name: 'Group D', value: 1, date: '2024-12-01' },
    ];


    // const aarData = [
    //     {
    //         head: '2023-08',
    //         value1: 30,
    //         value2: 20,
    //         value3: 5,
    //     },
    //     {
    //         head: '2023-09',
    //         value1: 40,
    //         value2: 20,
    //         value3: 10,
    //     },
    //     {
    //         head: '2023-10',
    //         value1: 17,
    //         value2: 12,
    //         value3: 23.00,
    //     },
    //     {
    //         head: '2023-11',
    //         value1: 9,
    //         value2: 0,
    //         value3: 0.00,
    //     },
    //     {
    //         head: '2023-12',
    //         value1: 0,
    //         value2: 0,
    //         value3: null,
    //     },
    // ];
    useEffect(() => {

        let param = { userCode: null };

        getApiCallDynamic({ path: API.getReportLoanTerm, param: param }).then((res) => {
            console.log(" Pie data fetched main 1 --> " + JSON.stringify(res), null, 2)
            if (res?.status === 200) {
                setLoanTermPercentage(res.data);
            }
        });
        getApiCallDynamic({ path: API.getReportLoanType, param: param }).then((res) => {
            console.log(" Pie data fetched main 2 -->  " + JSON.stringify(res), null, 2)
            if (res?.status === 200) {
                setLoanTypePercentage(res.data);
            }
        });

        getApiCallDynamic({ path: API.getReportLoanMiles, param: param }).then((res) => {
            console.log(" Loan miles data -->  " + JSON.stringify(res), null, 2)
            if (res?.status === 200) {
                setLoanMilesData(res.data);
            }
        });

        getApiCallDynamic({ path: API.getReportAAR, param: param }).then((res) => {
            console.log(" AAR data -->  " + JSON.stringify(res), null, 2)
            if (res?.status === 200) {
                setAarData(res.data);
            }
        });

    }, []);

    // return (
    // <div>
    //     {showChart ? (
    //        
    //         <>
    //             {activeComponent === 'Chart1' && <CustomStackedBarChart onBack={handleBack} data={loanMilesData} />}
    //             {activeComponent === 'Chart2' && <Chart2 onBack={handleBack} />}
    //             {activeComponent === 'Chart3' && <Chart3 onBack={handleBack} pieData={loanTermPercentage} />}
    //             {activeComponent === 'Chart4' && <Chart3 onBack={handleBack} pieData={loanTypePercentage} />}
    //             {activeComponent === 'Chart5' && <CustomBarChart onBack={handleBack} />}
    //         </>
    //     ) : (
    //        
    //         <>
    //             <TabList tabs={initialTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
    //             {/* <GridDisplay
    //                 content={activeContent}
    //                 handleButtonClick={(index) => {
    //                     // Handle button click and show chart with the selected component
    //                     handleGridButtonClick(index, activeContent, setActiveComponent);
    //                     setShowChart(true); 
    //                 }}
    //             /> */}
    //             <GridDisplay
    //                 content={activeContent}
    //                 handleButtonClick={(index) => {
    //                     const activeTabName = initialTabs.find(tab => tab.id === activeTab)?.name;
    //                     handleGridButtonClick(index, activeContent, setActiveComponent, activeTabName);
    //                     setShowChart(true); 
    //                 }}
    //             />

    //         </>
    //     )}
    // </div>
    //  );
    return (
        <div>

            {showChart ? (

                // <ChartContainer
                //     activeComponent={activeComponent}
                //     onBack={handleBack}
                //     additionalProps={{
                //         onBack: handleBack,
                //         data: activeComponent === 'Monthly Loan Product Analysis' ? loanMilesData : null,
                //         pieData: ['Loan Term', 'Loan Term'].includes(activeComponent) ?
                //             (activeComponent === 'Loan Term' ? loanTermPercentage : loanTypePercentage) : null
                //     }}
                // />
                <ChartContainer
                    activeComponent={activeComponent}
                    onBack={handleBack}
                    additionalProps={{
                        onBack: handleBack,
                        data:
                            activeComponent === 'Monthly Loan Product Analysis' ? loanMilesData :
                                activeComponent === 'Loan Term' ? loanTermPercentage :
                                    activeComponent === 'Loan Category' ? loanTypePercentage :
                                        activeComponent === 'Application Approval Rate' ? aarData :
                                            activeComponent === 'Sample' ? initialData : null,
                    }}
                />

            ) : (

                <>
                    <Typography variant="h2">
                        Loan performance analysis dashboard
                    </Typography>
                    <TabList tabs={initialTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                    <GridDisplay
                        content={activeContent}
                        handleButtonClick={(index) => {
                            const activeTabName = initialTabs.find(tab => tab.id === activeTab)?.name;
                            handleGridButtonClick(index, activeContent, setActiveComponent, activeTabName);
                            setShowChart(true);
                        }}
                    />
                </>
            )}
        </div>
    );


};

export default DashboardMain;
