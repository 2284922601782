
import React, { useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomDateRangePicker from './CustomDateRangePicker';
import Chart1 from './../charts/Chart1';
import Chart2 from './../charts/Chart2';
import Chart3 from './../charts/Chart3';
import CustomBarChart from './../charts/CustomBarChart';
import CustomPieChart from './../charts/CustomPieChart';
import CustomStackedBarChart from './../charts/CustomStackedBarChart';
import { KSBackArrowIcon } from '../../../icons/custome-icon';

import { enUS } from 'date-fns/locale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import CustomGroupedBarChart from './CustomGroupedBarChart';





export const ChartContainer = ({ activeComponent, onBack, additionalProps }) => {
    const [isApplied, setIsApplied] = useState(false);
    const [dateSelection, setDateSelection] = useState({ type: '', selectedDate: null, startDate: null, endDate: null });
    const [filteredData, setFilteredData] = useState([]);


    const handleDateChange = useCallback(({ type, selectedDate, startDate, endDate }) => {
        setDateSelection({ type, selectedDate, startDate, endDate });
    }, []);

    const handleApply = () => {
        console.log('Applying date filter:', dateSelection);


        const data = [
            { "name": "Group A", "value": 2.5, "date": "2024-09-01" },
            { "name": "Group B", "value": 4, "date": "2024-10-01" },
            { "name": "Group C", "value": 3.5, "date": "2024-11-01" },
            { "name": "Group D", "value": 1, "date": "2024-12-01" }
        ];

        let filtered = [];


        if (dateSelection.type === 'singleDate' && dateSelection.selectedDate) {
            const selectedDateJS = dateSelection.selectedDate.toDate();
            filtered = data.filter(item => {
                const itemDate = new Date(item.date);
                return itemDate.toDateString() === selectedDateJS.toDateString();
            });
        } else if (dateSelection.type === 'dateRange' && dateSelection.startDate && dateSelection.endDate) {
            const startDateJS = dateSelection.startDate.toDate();
            const endDateJS = dateSelection.endDate.toDate();
            filtered = data.filter(item => {
                const itemDate = new Date(item.date);
                return itemDate >= startDateJS && itemDate <= endDateJS;
            });
        }

        setFilteredData(filtered);
        setIsApplied(true);
        renderChart()
    };

    //render 
    const renderChart = () => {
        switch (activeComponent) {
            case 'Monthly Loan Product Analysis':
                return <CustomStackedBarChart data={additionalProps?.data} />;
            case 'Sample':
                return <Chart1 data={filteredData} />;
            case 'Loan Term':
                return <Chart3 data={additionalProps?.data} />;
            case 'Loan Category':
                return <Chart3 data={additionalProps?.data} />;
            case 'Chart5':
                return <CustomBarChart data={additionalProps?.data} />;
            case 'Application Approval Rate':
                return <CustomGroupedBarChart data={additionalProps?.data} />;
            default:
                return <div>No chart found</div>;
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <Box display="flex" alignItems="center" mb={2}>
                    <IconButton onClick={onBack} aria-label="back">
                        <KSBackArrowIcon />
                    </IconButton>
                    <Typography variant="h6" component="h2" ml={1}>
                        {activeComponent}
                    </Typography>
                </Box>


                <CustomDateRangePicker onDateChange={handleDateChange} />


                <Button variant="contained" onClick={handleApply} sx={{ mb: 3 }}>
                    Apply
                </Button>


                {isApplied && renderChart()}
            </Box>
        </LocalizationProvider>
    );
};
