import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { getApiCallDynamic } from '../../services/api-service';
import { API } from '../../services/api-config';
import { useRecoilState } from 'recoil';
import { analyticsDataState, profileState } from '../../recoil/profile-atom';
import { Dashboard } from '@mui/icons-material';
import DashboardMain from './DashboardMain';

const AnalyticsContainer = () => {







    return (
        <>

            <Box
            // sx={{ border: '1px solid red' }}
            >

                <DashboardMain></DashboardMain>

            </Box>

        </>

    )
}

export default AnalyticsContainer